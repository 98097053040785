import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Button, Spinner } from 'react-bootstrap';
import { CustomToast } from '@src/components/toast';
import { ChangelogTags, CreateChangelog } from './types';
import { createChangelog, getAllChangelogs } from '@src/Api';
import {
  BtnBold,
  BtnItalic,
  BtnRedo,
  BtnUndo,
  ContentEditableEvent,
  Editor,
  EditorProvider,
  Toolbar,
  Separator,
  BtnUnderline,
  BtnStrikeThrough,
  BtnLink,
  BtnStyles,
  BtnNumberedList,
  BtnBulletList,
} from 'react-simple-wysiwyg';
import { ChangelogComponent } from '../../components/changelog';
import { CustomTag } from '@src/components/tags';
import { Changelog } from '@src/components/changelog/types';

export const ChangelogAdminPage: React.FC = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastError, setToastError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<string>('');
  const [version, setVersion] = useState<string>('');
  const [html, setHtml] = useState<string>('');
  const [tags, setTags] = useState<ChangelogTags[]>([]);
  const [tagText, setTagText] = useState('');
  const [tagColor, setTagColor] = useState('#6c757d');
  const [errors, setErrors] = useState<{ date: boolean; version: boolean }>({ date: false, version: false });
  const [changelogs, setChangelogs] = useState<Changelog[]>([]);

  const fetchChangeLogs = async () => {
    const response = await getAllChangelogs();
    if (response) {
      setChangelogs(response.data);
    }
  };

  useEffect(() => {
    fetchChangeLogs();
  }, []);

  const handleAddTag = () => {
    if (tagText.trim()) {
      setTags([...tags, { text: tagText, color: tagColor }]);
      setTagText('');
      setTagColor('#6c757d');
    }
  };

  const handleRemoveTag = (index: number) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const handleChange = (e: ContentEditableEvent) => {
    setHtml(e.target.value);
  };

  const handleChangelogCreation = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    const hasErrors = {
      date: !date,
      version: !version,
    };

    setErrors(hasErrors);

    if (hasErrors.date || hasErrors.version) {
      return;
    }

    setLoading(true);

    const changelogToCreate: CreateChangelog = {
      changelog_version: version,
      changelog_description: html,
      changelog_created: new Date(date).toISOString().slice(0, 19).replace('T', ' '),
      changelog_tags: tags,
    };

    const response = await createChangelog(changelogToCreate);

    if (response?.status !== 201 && response?.statusText) {
      const errorMessage = response?.statusText || 'An unknown error occurred.';
      setToastError(errorMessage);
      setShowToast(true);
    } else {
      fetchChangeLogs();
      setToastError(null);
      setShowToast(true);
      setTags([]);
      setHtml('');
      setVersion('');
      setDate('');
    }
    setLoading(false);
  };

  return (
    <>
      <Row className="admin">
        <Col className="p-0">
          <Row className="admin-header-row">
            <Col>
              <h1 className="admin-header">Add new changelog entry</h1>
              <hr className="mt-2" />
            </Col>
          </Row>

          {/* Changelog Form */}
          <Row className="admin-form-row">
            <Col md={12}>
              <Form>
                <Form.Group className="admin-form-input">
                  <Form.Label>Version</Form.Label>
                  <Form.Label className="required-star">*</Form.Label>
                  <Form.Control placeholder="Enter version" value={version} onChange={(e) => setVersion(e.target.value)} isInvalid={errors.version} />
                  <Form.Control.Feedback type="invalid">Version is required</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-3 admin-form-input">
                  <Form.Label>Date</Form.Label>
                  <Form.Label className="required-star">*</Form.Label>
                  <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)} isInvalid={errors.date} />
                  <Form.Control.Feedback type="invalid">Date is required</Form.Control.Feedback>
                </Form.Group>

                {/* Tags Section */}
                <Form.Group className="mt-3 admin-form-input">
                  <Form.Label>Add Tags</Form.Label>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <Form.Control type="text" placeholder="Tag text" value={tagText} onChange={(e) => setTagText(e.target.value)} />
                    <Form.Control type="color" value={tagColor} onChange={(e) => setTagColor(e.target.value)} />
                    <Form.Control type="text" placeholder="#Hexcode" value={tagColor} onChange={(e) => setTagColor(e.target.value)} />
                    <Button className="mt-0 admin-form-btn" onClick={handleAddTag}>
                      +
                    </Button>
                  </div>

                  {/* Render Tags */}
                  <div>
                    {tags.map((tag, index) => (
                      <div key={index} className="tag-container">
                        <CustomTag text={tag.text} color={tag.color} />
                        <span className="tag-remove" onClick={() => handleRemoveTag(index)}>
                          &times;
                        </span>
                      </div>
                    ))}
                  </div>
                </Form.Group>

                {/* WYSIWYG Editor */}
                <Form.Group className="mt-3">
                  <EditorProvider>
                    <Toolbar>
                      <BtnUndo />
                      <BtnRedo />
                      <Separator />
                      <BtnBold />
                      <BtnItalic />
                      <BtnUnderline />
                      <BtnNumberedList />
                      <BtnBulletList />
                      <BtnStrikeThrough />
                      <Separator />
                      <BtnLink />
                      <BtnStyles />
                    </Toolbar>
                    <Editor value={html} onChange={handleChange} />
                  </EditorProvider>
                </Form.Group>

                {/* Submit Button */}
                <Form.Group className="mt-3 mb-5">
                  {!loading ? (
                    <Button className="admin-form-btn" type="submit" onClick={(e) => handleChangelogCreation(e)}>
                      Create
                    </Button>
                  ) : (
                    <Spinner animation="border" />
                  )}
                </Form.Group>
              </Form>
            </Col>
          </Row>

          <Container className="toast-b-right">
            {/* Toast Notification */}
            {showToast && (
              <CustomToast
                duration={10000}
                show={showToast}
                title={toastError ? 'Error' : 'Changelog entry created'}
                message={toastError || 'New changelog entry was successfully created.'}
                onClose={() => setShowToast(false)}
                type={toastError ? 'error' : 'success'}
              />
            )}
          </Container>

          <hr />

          {/* Display Changelog Entries */}
          <ChangelogComponent changelogs={changelogs} setChangelogs={setChangelogs} isAdmin={true} />
        </Col>
      </Row>
    </>
  );
};
