import React, { useEffect, useState } from 'react';
import { ChangelogComponent } from '@src/components/changelog';
import { Col, Row } from 'react-bootstrap';
import { Changelog } from '@src/components/changelog/types';
import { getAllChangelogs } from '@src/Api';

export const ChangelogPage = () => {
  const [changelogs, setChangelogs] = useState<Changelog[]>([]);

  useEffect(() => {
    const fetchChangeLogs = async () => {
      const response = await getAllChangelogs();
      if (response) {
        setChangelogs(response.data);
      }
    };
    fetchChangeLogs();
  }, []);

  return (
    <Row className="admin">
      <Col className="p-0">
        <Row className="admin-header-row">
          <Col>
            <h1 className="admin-header">Changelog</h1>
            <hr className="mt-2" />
          </Col>
        </Row>

        <ChangelogComponent changelogs={changelogs} setChangelogs={setChangelogs} isAdmin={false} />
      </Col>
    </Row>
  );
};
