import React, { useState } from 'react';
import { ChangelogProps } from './types';
import { deleteChangelog } from '@src/Api';
import { CustomTag } from '@src/components/tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Spinner } from 'react-bootstrap';

export const ChangelogComponent: React.FC<ChangelogProps> = ({ changelogs, setChangelogs, isAdmin }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [changelogToDelete, setChangelogToDelete] = useState<number | null>(null);

  const confirmDeleteUser = async () => {
    if (changelogToDelete === null) return;

    setLoading(true);
    try {
      const response = await deleteChangelog(changelogToDelete);
      if (response?.status === 200) {
        setChangelogs(changelogs.filter((log) => log.changelog_id !== changelogToDelete));
        setChangelogToDelete(null);
      }
    } catch (error) {
      console.error('Failed to delete user:', error);
    } finally {
      setShowModal(false);
      setLoading(false);
    }
  };

  const handleDeleteChangelog = (changelogId: number) => {
    setChangelogToDelete(changelogId);
    setShowModal(true);
  };

  return (
    <>
      <div className="changelog-container">
        {changelogs.map((log) => (
          <div className="changelog-item" key={log.changelog_id}>
            <div className="changelog-metadata">
              <span>Version {log.changelog_version}</span>
              <div className="changelog-div">
                <CustomTag text={log.changelog_created ? new Date(log.changelog_created).toLocaleDateString('en-GB') : 'Invalid Date'} color="#3a4e48" />
              </div>
              {log.changelog_tags.map((tag, index) => (
                <div className="changelog-div" key={index}>
                  <CustomTag text={tag.text} color={tag.color} />
                </div>
              ))}
            </div>
            <p className="changelog-description" dangerouslySetInnerHTML={{ __html: log.changelog_description }} />
            {isAdmin && (
              <button className="admin-users-table-btn admin-users-table-btn-delete" onClick={() => handleDeleteChangelog(log.changelog_id)}>
                <span>Delete</span>
                <FontAwesomeIcon icon="trash-can" />
              </button>
            )}
            <hr />
          </div>
        ))}
      </div>

      <Modal className="modal-placement" show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this changelog entry?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          {loading ? <Spinner className="custom-spinner-small-modal"></Spinner> : <Button onClick={confirmDeleteUser}>Yes, Delete</Button>}
        </Modal.Footer>
      </Modal>
    </>
  );
};
