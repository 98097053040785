import React from 'react';
import { CustomTagProps } from './types';

export const CustomTag: React.FC<CustomTagProps> = ({ text, color }) => {
  return (
    <span className="custom-tag" style={{ backgroundColor: color }}>
      {text}
    </span>
  );
};
